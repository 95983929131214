import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "../../components/UserLogin/QRScan";
import * as url from "../../api/uri_helper";
import ReactPlayer from "react-player";
import { Breadcrumb } from "antd";
import '../../../src/custom.css'
import Modal from "react-bootstrap/Modal";
// Import Layout
import Layout from "../Layout";
import Search from "../../UserAssets/images/search1.svg";
import logo from "../../UserAssets/images/authenteklogo.png";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import SupportRequest from "../UserLogin/SupportRequest";

import BackBtn from "../../UserAssets/images/arrow-left.svg";
import device1 from "../../UserAssets/images/img2.png";
import Plus from "../../UserAssets/images/plus.svg";
import Copy from "../../UserAssets/images/copy.svg";
import Menu from "../../UserAssets/images/toggle.svg";
import PDFnew from "../../UserAssets/images/pdf-icn.svg";
import downloadIcon from "../../UserAssets/images/download-icn.svg";
import UrlImage from "../../UserAssets/images/attach.svg";
import { Tooltip } from 'antd';
import Close from "../../UserAssets/images/close.svg";
import Circle from "../../UserAssets/images/play-circle 1.svg";
//import css
import "../../UserAssets/css/animate.css";
import "../../UserAssets/css/bootstrap.min.css";
import "../../UserAssets/css/style.css";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import Loader from "../loader";
import "./QRThumbnail";
import {
    fetchAllQrCodes,
    fetchAllQrId,
    fetchAllQrIdByGuideType,
    fetchQRdetailsById,
} from "../../Redux/Api/fetch.api";
import { setQrCodeIdData } from "../../Redux/Actions/QrCodeId";
import Header from "../UserLayout/Header";
import Footer from "../UserLayout/Footer";
import { setQrCodeIdDataByGuideType } from "../../Redux/Actions/QRCodeIdByGuideType";
import { isNull } from "util";
import { getQrCodeData } from "../../Redux/Actions/QR";


const DetailedReview = () => {
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    var userData = null;
    const locationObject = useLocation();
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [supportModalDisplay, setSupportModalDisplay] = useState(false);
    const parentQrCodeIdnew = locationObject.state
        ? locationObject.state.parentQrCodeIdnew
        : "";
    const qrGroupingDetails = useSelector(
        (state) => state.allUserGroupingReducer.allUserData
    ); // [] , class /object

    var parentId =
        qrGroupingDetails.parentThumbnail &&
        qrGroupingDetails.parentThumbnail.qrCode;
    const allQRCodeIdData = Object.keys(qrGroupingDetails).length > 0 &&
        Object.entries(qrGroupingDetails.qrGrouping);
    const [savedHomeId, setSavedHomeId] = useState(null);
    const id = qrGroupingDetails && qrGroupingDetails.qrCode;
    const history = useHistory();
    const location = useLocation();
    const { homeId } = location.state || {};
    const [currentMediaId, setCurrentMediaId] = useState();
    const [qrDescription, setqrDescription] = useState(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [viewpdf, setViewpdf] = useState(null);
    const [qrId, setQRId] = useState();
    const allQrData = useSelector((state) => state.allQrCodeData.qrTypes);


    var urlId = null;
    var product_Name = allQrData
        .filter((item, index) => item.qRcodeId == qrGroupingDetails.qrCode)
        .map((item) => item.product_Name);

    const dataId = location.state ? location.state.nextId : "";

    useEffect(() => {
        setSavedHomeId(homeId);
        let urlData = location.pathname.split("/");
        urlId = urlData[2];
        if (urlData[2]) {

            fetchQRdetailsById({
                mediaId: urlId
            })
                .then((response) => {


                    dispatch(
                        setQrCodeIdDataByGuideType(response.data.data)

                    );
                    userData = response.data.data
                    setLoading(false);

                })
                .catch((err) => {
                    console.log("Err: ", err);
                });
        }

    }, [urlId]);

    useEffect(() => {
        let urlData = location.pathname.split("/");

        if (allQRCodeIdData.length == undefined && qrGroupingDetails.length == 0) {

        } else {
            if (allQRCodeIdData != null && allQRCodeIdData.length > 0) {
                const getDataFromGroupingDetails = Object.keys(
                    Object.entries(qrGroupingDetails.qrGrouping)
                ).map(function (key) {
                    return Object.entries(qrGroupingDetails.qrGrouping)[key][1];
                });

                var getQRDescBasedonMediaID = getDataFromGroupingDetails
                    .flat()
                    .find((x) => x.mediaId == urlData[2]);
                setqrDescription(getQRDescBasedonMediaID);
                setQRId(getQRDescBasedonMediaID.qRcodeId);
                setViewpdf(getQRDescBasedonMediaID.mediaType == "pdf" ? getQRDescBasedonMediaID.filename : "");
            }
            setLoading(false);
        }
    }, [qrGroupingDetails]);

    const backPage = (qrCode) => {

        history.push({
            pathname: "/QRThumbnail/" + qrCode,
            state: {
                currentId: currentId,
            },
        });
    };


    return (
        <div>
            {loading ? <Loader /> : ""}
            <Header
                isId={id}
                parentQrCodeIdnew={parentQrCodeIdnew}
                qrGroupingDetails={qrGroupingDetails}
                setSupportModalDisplay={setSupportModalDisplay}
                isSupportIcon={true}
            ></Header>
            <section class="center-section inner-pages text-center">
                <div className="Support-request-show">
                    <SupportRequest supportModalDisplay={supportModalDisplay} setSupportModalDisplay={setSupportModalDisplay} />
                </div>
                <div class="Qr-detail m-0 ">
                    {qrDescription ? (
                        <div>
                            <div class="container">
                                <Breadcrumb className="breadcrumb">
                                    <Breadcrumb.Item>
                                        <Link to={`/${savedHomeId}`}>
                                            <i class="fa-solid fa-house"></i>
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={`/QRThumbnail/${id}`}>
                                            {qrGroupingDetails && qrGroupingDetails.qrCode}
                                        </Link>
                                    </Breadcrumb.Item>
                                </Breadcrumb>

                                {qrDescription.mediaType != "pdf" ?
                                    <div class="QR-detail">
                                        {qrDescription.mediaType != "Text (HTML)" ?
                                            <div class="qrImgbox no-shadow wow fadeInUp">
                                                {qrDescription.mediaType == "Image" ? (
                                                    <img src={qrDescription.filename}></img>
                                                ) : qrDescription.mediaType == "video" ? (
                                                    <ReactPlayer
                                                        url={qrDescription.filename}
                                                        width="100%"
                                                        height="98%"
                                                        controls={true}
                                                        playing={false}
                                                         volume={0.5} 
                                                    />
                                                ) : qrDescription.mediaType == "Video Url" ? (

                                                    <ReactPlayer
                                                        url={qrDescription.filename}
                                                        width="100%"
                                                        height="98%"
                                                        controls={true}
                                                                playing={false}
                                                                volume={0.5} 
                                                    />
                                                )

                                                    : (
                                                        ""
                                                    )
                                                }

                                                {qrDescription && qrDescription.mediaType == "Image" ? (
                                                    <div
                                                        class="btn-outer zoom-detail-page wow fadeInUp"
                                                        data-wow-delay="0.5s"
                                                    >
                                                        {" "}
                                                        <button
                                                            class="zoom-btn"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#zoom-btn"
                                                        >
                                                            <img src={Plus}></img> Click to zoom image
                                                        </button>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div> : ""
                                        }
                                        {qrDescription.mediaType != "pdf" ?
                                            <div class="qrImgtext">
                                                <h4 class="wow fadeInUp" data-wow-delay="0.3s">
                                                    {qrDescription.header == "undefined" ? "" : qrDescription.header}
                                                </h4>
                                                <div class="info-text">
                                                    {product_Name[0] == null ? (
                                                        ""
                                                    ) : (
                                                        <>
                                                            <strong class="wow fadeInUp" data-wow-delay="0.8s">
                                                                Product Information :
                                                            </strong>
                                                            <div className="row mb-2">
                                                                <div
                                                                    className="col-5 wow fadeInUp"
                                                                    data-wow-delay="0.6s"
                                                                >
                                                                    <p>Product Name : </p>
                                                                </div>
                                                                <p className="col-7 mb-2"> {product_Name}</p>
                                                            </div>
                                                        </>
                                                    )}
                                                    <strong class="wow fadeInUp" data-wow-delay="0.8s">
                                                        {" "}
                                                        Company Information :
                                                    </strong>
                                                    <div className="row">
                                                        <div
                                                            className="col-5 wow fadeInUp"
                                                            data-wow-delay="0.6s"
                                                        >
                                                            <p>Company Name : </p>
                                                        </div>

                                                        <p className="col-7 mb-1">
                                                            {qrDescription.companyName}
                                                        </p>
                                                    </div>
                                                    <div className="row mb-1">
                                                        <div
                                                            className="col-5 wow fadeInUp"
                                                            data-wow-delay="0.6s"
                                                        >
                                                            <p>Location Name :</p>
                                                        </div>
                                                        <p className="col-7">{qrDescription.name}</p>
                                                    </div>
                                                </div>
                                                <div class="info-text">
                                                    <strong class="wow fadeInUp" data-wow-delay="0.8s">
                                                        {" "}
                                                        Guide Information :
                                                    </strong>
                                                    <p class="wow fadeInUp" data-wow-delay="0.9s">
                                                        {" "}
                                                        {qrDescription.guideType}
                                                    </p>
                                                </div>

                                                <div class="info-text">
                                                    <strong class="wow fadeInUp" data-wow-delay="0.4s">
                                                        QR Description :
                                                    </strong>
                                                    <p class="wow fadeInUp" data-wow-delay="0.5s">
                                                        {qrDescription.qR_Description}
                                                    </p>
                                                </div>
                                                <div class="info-text">
                                                    <strong class="wow fadeInUp" data-wow-delay="0.4s">
                                                        Media Description :
                                                    </strong>
                                                    <div class="html-text">
                                                        <div dangerouslySetInnerHTML={{
                                                            __html: qrDescription.mediaDescription,
                                                        }}>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                    </div> :
                                    <div className="Sales-container container pdfview ">
                                        {/*<iframe src={pdfurlnew} scrolling="yes" type="application/pdf" width="100%"*/}
                                        {/*    height="600" ></iframe>*/}
                                        {qrDescription.mediaType == "pdf" ?
                                            <div className="pdf-outer">
                                                <Tooltip title="Click to Download">
                                                    <a
                                                        className="pdf-download-btn1 d-flex align-items-center"
                                                        target="blank"
                                                        href={viewpdf}
                                                    >
                                                        <img src={downloadIcon}></img>
                                                    </a>
                                                </Tooltip>
                                            </div> : ""}
                                        <Document file={{
                                            url:
                                                viewpdf
                                        }}
                                            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                                        >
                                            {Array.apply(null, Array(numPages))
                                                .map((x, i) => i + 1)
                                                .map(page => <Page pageNumber={page} renderTextLayer={false} />)}
                                        </Document>
                                    </div>
                                }
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </section>
            <Footer></Footer>
            <div
                class="modal fade"
                id="zoom-btn"
                tabindex="-1"
                aria-labelledby="zoom-btn"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button
                                type="button"
                                class="btn-closee"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <img src={Close}></img>
                            </button>
                            <div class="QRImg">
                                {qrDescription ? (
                                    <img src={qrDescription.filename} />
                                ) : (
                                    <img src={qrDescription && qrDescription.filename} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default connect()(DetailedReview);
