import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import "../../components/UserLogin/QRScan";
import * as url from "../../api/uri_helper";
import "./QRListing";
import ReactPlayer from "react-player";
import Modal from "react-bootstrap/Modal";
import "./DetailedReview";
import '../../../src/custom.css'
// Import Layout
import Close from "../../UserAssets/images/close.svg";
import GridView from "../../UserAssets/images/gridview.svg";
import ListView from "../../UserAssets/images/listview.svg";
import Loader from "../loader";
// PDF from "../../UserAssets/images/pdf.png";
import PDF from "../../UserAssets/images/pdf-icon.svg";
import zoom from "../../UserAssets/images/zoom.svg";
import play from "../../UserAssets/images/play.svg";
import thumb from "../../UserAssets/images/noImage.jpg";
//import css
import "../../UserAssets/css/animate.css";
import "../../UserAssets/css/bootstrap.min.css";
import "../../UserAssets/css/style.css";
import { getQrDataDetails } from "../../Redux/Actions/QrDetails";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";
import thumbnailImage from "../../UserAssets/images/thumbnail_image.png";
import {
    fetchAllQrId,
    fetchAllQrIdByGuideType,
    fetchQRdetailsById,
} from "../../Redux/Api/fetch.api";
import { QrReader } from "react-qr-reader";
import { setQrCodeIdDataByGuideType } from "../../Redux/Actions/QRCodeIdByGuideType";
import BackBtn from "../../UserAssets/images/arrow-left.svg";
import Header from "../UserLayout/Header";
import Footer from "../UserLayout/Footer";
import QRParentChild from "../UserLogin/QRParentChild";
import { Breadcrumb } from "antd";
import SupportRequest from "../UserLogin/SupportRequest";
import QRCodeGenerator from "../UserLogin/QRCodeGenerator";

const QRThumbnail = () => {
    // const allQRIdBasedData = useSelector((state) => state.allQrCodeIdReducers.allQRCodeId)
    const locationObject = useLocation();
    const parentQrCodeId = locationObject.state
        ? locationObject.state.parentQrCodeId
        : "";
    const parentQrCodeIdlatest = locationObject.state
        ? locationObject.state.parentQrCodeIdlatest
        : "";
    const [savedHomeId, setSavedHomeId] = useState(null);
    const qrGroupingDetails = useSelector(
        (state) => state.allUserGroupingReducer.allUserData
    ); // [] , class /object
    const allQRCodeIdData =
        Object.keys(qrGroupingDetails).length > 0 &&
        Object.entries(qrGroupingDetails.qrGrouping);

    var parentId =
        qrGroupingDetails.parentThumbnail &&
        qrGroupingDetails.parentThumbnail.qrCode;
    var parentproductname = qrGroupingDetails && qrGroupingDetails.product_Name;
    var newparentproductname =
        qrGroupingDetails.parentThumbnail &&
        qrGroupingDetails.parentThumbnail.parentProduct_Name;

       
    console.log("groupong", parentproductname)
    var childId =
        qrGroupingDetails.childThumbnail && qrGroupingDetails.childThumbnail.qrCode;
    const allQrData = useSelector((state) => state.allQrCodeData.qrTypes);
   
    const [showmodal, setShowModal] = useState(false);
    var product_Name = allQrData
        .filter((item, index) => item.qRcodeId == qrGroupingDetails.qrCode)
        .map((item) => item.product_Name);
    const history = useHistory();
    const [id, setId] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();
    const [detailDesc, setDetailDesc] = useState();
    const [showZoomImage, setShowZoomImage] = useState();
    const [loading, setLoading] = useState(true);
    const [qRCode, setQRCode] = useState();
    const location = useLocation();
    const { currentId } = location.state || {};
    const [modal, setModal] = useState(false);
    const [islistView, setIsListView] = useState(true);
    const [supportModalDisplay, setSupportModalDisplay] = useState(false);
    const parentQRId = location.state ? location.state.parentDataId : "";

    useEffect(() => {
        setSavedHomeId(currentId);
        let urlData = location.pathname.split("/");
        setId(urlData[2]);
        fetchQRdetailsById({
            QrCode: urlData[2],
        })
            .then((response) => {
                dispatch(setQrCodeIdDataByGuideType(response.data.data));
                if (response.data.success) {
                    // setId(urlData[2])
                    setQRCode(response.data.data.qrCode);
                    setLoading(false);
                } else {
                    setErrorMessage("Qr Code is not valid, please try again.");
                }
            })
            .catch((err) => {
                console.log("Err: ", err);
            });
    }, []);

    const handleClose = () => {
        setModal(false);
        setShowModal(false);
    };

    const next = (mediaId, id) => {
        history.push({
            pathname: "/QRDescription/" + mediaId,
            state: {
                nextId: id,
            },
        });
    };

    const parent = () => {
        history.push(`/${parentId}`);

        window.location.reload();
    };

    const child = () => {
        history.push(`/${childId}`);
        window.location.reload();
    };

    const handleListViewClick = () => {
        setIsListView(true); // Set state to true when ListView is clicked
    };

    const handleGridViewClick = () => {
        setIsListView(false); // Set state to true when ListView is clicked
    };
    const handleQRModalShow = (mediaDescription) => {
        setModal(true);
        if (mediaDescription) setDetailDesc(mediaDescription);
    };

    const handleQRImageShow = (thumbnailName) => {
        setShowModal(true);
        setShowZoomImage(thumbnailName);
    };

    return (
        <div>
            <Header
                isParentId={parentId}
                parentQRId={parentQRId}
                isQRCode={qRCode}
                id={id}
                parentQrCodeId={parentQrCodeId}
                parentQrCodeIdlatest={parentQrCodeIdlatest}
                setSupportModalDisplay={setSupportModalDisplay}
                isSupportIcon={true}
            ></Header>

            <section class="center-section inner-pages text-center p-0">
                {loading ? <Loader /> : ""}
                <div className="Support-request-show">
                    <SupportRequest supportModalDisplay={supportModalDisplay} setSupportModalDisplay={setSupportModalDisplay} />
                </div>
                <div>
                    <div class="Qr-detail m-0">
                        {allQRCodeIdData != null ? (
                            <div>

                                <div class="container">
                                    <div className="breadcrumb-container pl-0 " >
                                        <Breadcrumb className="breadcrumb QrParentChild">
                                            <Breadcrumb.Item>
                                                {currentId != null ? (
                                                    <Link to={`/${currentId}`}>
                                                        <i className="fa-solid fa-house"></i>
                                                    </Link>
                                                ) : parentId != null ? (
                                                    <Link to={`/${parentId}`}>
                                                        <i className="fa-solid fa-house"></i>
                                                    </Link>
                                                ) : (
                                                            <Link to={`/${savedHomeId}`}>
                                                        <i className="fa-solid fa-house"></i>
                                                    </Link>
                                                )}
                                            </Breadcrumb.Item>
                                            {parentId != 0 ? (
                                                <Breadcrumb.Item>
                                                    <Link to={`/${parentId}`}>{parentId}</Link>
                                                </Breadcrumb.Item>
                                            ) : (
                                                ""
                                            )}
                                            <Breadcrumb.Item>
                                                <Link to
                                                    ={`/QRThumbnail/${qrGroupingDetails &&
                                                        qrGroupingDetails.qrCode}`}
                                                >
                                                    {qrGroupingDetails && qrGroupingDetails.qrCode}
                                                </Link>
                                            </Breadcrumb.Item>
                                            {qrGroupingDetails &&
                                                qrGroupingDetails.qrCode != id ? (<Breadcrumb.Item>
                                                    <Link to={`/${id}`}> {id}</Link>
                                                </Breadcrumb.Item>)
                                                : (
                                                    ""
                                                )}

                                        </Breadcrumb>
                                    </div>
                                    {parentId ? (
                                        <div className="sticky-parent-thumbnail">
                                            <div className="wh_detail">
                                                {" "}
                                                {parentQrCodeId ? (
                                                    <Link to={`/${parentQrCodeId}`}>
                                                        <div className="leftImg">
                                                            {qrGroupingDetails.parentThumbnail &&
                                                                qrGroupingDetails.parentThumbnail.thumbnail ? (
                                                                <img
                                                                    src={
                                                                        qrGroupingDetails.parentThumbnail &&
                                                                        qrGroupingDetails.parentThumbnail.thumbnail
                                                                    }
                                                                />) : (
                                                                <img src={thumbnailImage} />
                                                            )}
                                                        </div>{" "}
                                                        <div className="QRtext justify-content-center">
                                                            <h5>{newparentproductname}</h5>
                                                        <p>QR - {parentQrCodeId} </p>{" "}
                                                          
                                                        </div>
                                                    </Link>
                                                ) : parentQrCodeIdlatest == qrGroupingDetails.qrCode ? (
                                                    <Link to={`/${parentId}`}>
                                                        <div className="leftImg">
                                                            {qrGroupingDetails.parentThumbnail &&
                                                                qrGroupingDetails.parentThumbnail.thumbnail ? (
                                                                <img
                                                                    src={
                                                                        qrGroupingDetails.parentThumbnail &&
                                                                        qrGroupingDetails.parentThumbnail.thumbnail
                                                                    }
                                                                />) : (
                                                                <img src={thumbnailImage} />
                                                            )}
                                                            </div>
                                                            <div className="QRtext justify-content-center">
                                                                <h5>{newparentproductname}</h5>
                                                            <p>QR - {parentId} </p>
                                                               
                                                            </div>
                                                    </Link>
                                                ) : parentQrCodeIdlatest ? (
                                                    <Link to={`/${parentQrCodeIdlatest}`}>
                                                        <div className="leftImg">
                                                            {qrGroupingDetails.parentThumbnail &&
                                                                qrGroupingDetails.parentThumbnail.thumbnail ? (
                                                                <img
                                                                    src={
                                                                        qrGroupingDetails.parentThumbnail &&
                                                                        qrGroupingDetails.parentThumbnail.thumbnail
                                                                    }
                                                                />) : (
                                                                <img src={thumbnailImage} />
                                                            )}
                                                                </div>{" "}
                                                                <div className="QRtext justify-content-center">
                                                                    <h5>{newparentproductname}</h5>
                                                                <p>QR - {parentQrCodeIdlatest}</p>{" "}
                                                                   
                                                                </div>
                                                    </Link>
                                                ) : (
                                                    <Link to={`/${parentId}`}>
                                                        <div className="leftImg">
                                                            {qrGroupingDetails.parentThumbnail &&
                                                                qrGroupingDetails.parentThumbnail.thumbnail ? (
                                                                <img
                                                                    src={
                                                                        qrGroupingDetails.parentThumbnail &&
                                                                        qrGroupingDetails.parentThumbnail.thumbnail
                                                                    }
                                                                />) : (<img
                                                                    src={
                                                                        thumbnailImage
                                                                    }
                                                                />)}
                                                                    </div>
                                                                    <div className="QRtext justify-content-center">
                                                                        <h5>{newparentproductname}</h5>
                                                                    <p>QR - {parentId} </p>
                                                                       
                                                                    </div>
                                                    </Link>
                                                )}

                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className="QRImgBox-outer">
                                        <div className="QRImgBox">
                                            <div className="ImgBox">
                                                {qrGroupingDetails.thumbnailName ? (
                                                    <img
                                                        src={
                                                            qrGroupingDetails.thumbnailName
                                                                ? qrGroupingDetails.thumbnailName
                                                                : ""
                                                        }
                                                    />
                                                ) : (
                                                    <img src={thumbnailImage} />
                                                )}

                                                <button
                                                    class="QRZoomBtn"
                                                    onClick={() =>
                                                        handleQRImageShow(qrGroupingDetails.thumbnailName)
                                                    }
                                                >
                                                    <img src={zoom} alt="Zoom" />
                                                </button>
                                            </div>
                                            <div className="QRtext justify-content-center">
                                                <h4>{parentproductname}</h4>
                                                <div className="d-flex ">
                                                    <div className="qr-main_code-inner-new w-100 m-0">
                                                        <div className="">
                                                            {" "}
                                                            QR - {id ? id : ""}
                                                        </div>
                                                    </div>

                                                    <div className="qr-container w-100 justify-content-end d-flex">
                                                        < QRCodeGenerator qrcodeid={id} />
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-1 view-option w-100 justify-content-end">
                                        <ul className="unstyled-list d-flex">
                                            <li className={islistView ? "active" : ""}>
                                                <img src={ListView}
                                                    onClick={handleListViewClick} 
                                                    title="Switch to List View" 
                                                    alt="" />
                                            </li>
                                            <li className={islistView ? "" : "active"}>
                                                <img src={GridView}
                                                    onClick={handleGridViewClick}
                                                    title="Switch to Grid View" 
                                                    alt="" />
                                            </li>
                                        </ul>


                                    </div>
                                    {allQRCodeIdData &&
                                        allQRCodeIdData.map((mainGroping) => (
                                            <div className="main-box">
                                                <div className="d-flex justify-content-between w-100">

                                                    <div className="Qr-main-heading w-100"> {mainGroping[0]}</div>
                                                   
                                                    </div>
                                               
                                                <div class="row wk-Qr-row">
                                                    {mainGroping[1] &&
                                                        mainGroping[1].map((details) => (
                                                            <div className={islistView ? "col-lg-12 col-md-6 col-12" : "col-lg-4 col-md-6 col-12"}>
                                                                {details.mediaType == "Image" ? (
                                                                    <Link className="h-100 d-inline-block w-100"
                                                                        to={{
                                                                            pathname:
                                                                                "/QRDescription/" + details.mediaId,
                                                                            state: {
                                                                                parentQrCodeIdnew: parentQrCodeId
                                                                                    ? parentQrCodeId
                                                                                    : id,
                                                                                homeId: savedHomeId, 
                                                                            },
                                                                        }}
                                                                    >
                                                                        <div
                                                                            class="list-thumbnail"
                                                                        >
                                                                            <div class="thumbnail-list-img">
                                                                                {thumb ? (
                                                                                    <img

                                                                                        className="objectFit"
                                                                                        src={details.filename}
                                                                                        onClick={() => next(details.mediaId)}
                                                                                        alt="First Image"
                                                                                    />
                                                                                ) : (

                                                                                    <img
                                                                                        className="objectFit"
                                                                                        src={thumb}
                                                                                        onClick={() => next(details.mediaId)}
                                                                                        alt="Thumbnail"
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                            {details.mediaDescription ? (
                                                                                <div class="Qr-list-text">
                                                                                    <strong>QR - {qRCode}</strong>
                                                                                    {details.mediaDescription &&
                                                                                        details.mediaDescription.length >=
                                                                                        10 ? (
                                                                                        <div className="flex-box">
                                                                                            <p>
                                                                                                {details.mediaDescription.substr(
                                                                                                    0,
                                                                                                    50
                                                                                                )}
                                                                                                {"... "}
                                                                                            </p>
                                                                                            {/*<div className="bottom-links">*/}
                                                                                            {/*        <a*/}
                                                                                            {/*            href="#"*/}
                                                                                            {/*            onClick={(e) => {*/}
                                                                                            {/*                e.preventDefault();*/}
                                                                                            {/*            handleQRModalShow(*/}
                                                                                            {/*                details.mediaDescription*/}
                                                                                            {/*            );*/}
                                                                                            {/*        }}*/}
                                                                                            {/*    >*/}
                                                                                            {/*        Show More*/}
                                                                                            {/*    </a>*/}
                                                                                            {/*</div>*/}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <p>{details.mediaDescription}</p>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="thumb-list-text no-data">
                                                                                    <p>No Description Available</p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Link>
                                                                ) : details.mediaType == "video" ? (
                                                                    <Link className="h-100 d-inline-block w-100"
                                                                        to={{
                                                                            pathname:
                                                                                "/QRDescription/" + details.mediaId,
                                                                            state: {
                                                                                parentQrCodeIdnew: parentQrCodeId
                                                                                    ? parentQrCodeId
                                                                                    : id,
                                                                                homeId: savedHomeId, 
                                                                            },
                                                                        }}
                                                                    >
                                                                        <div
                                                                            class="list-thumbnail"
                                                                        >
                                                                            <div class="thumbnail-list-img">
                                                                                <ReactPlayer
                                                                                    // url="https://vimeo.com/291715535"
                                                                                    url={details.filename}
                                                                                    width="100%"
                                                                                    height="100%"
                                                                                    controls={true}
                                                                                    playing={false}
                                                                                    onClick={() => next(details.mediaId)}
                                                                                />
                                                                                <div className="overlay-play-btn"><img src={play} alt="play" ></img></div>
                                                                            </div>
                                                                            {details.mediaDescription ? (
                                                                                <div
                                                                                    class="Qr-list-text"

                                                                                // onClick={() => next(details.mediaId)}
                                                                                >
                                                                                    <strong>QR - {qRCode}</strong>
                                                                                    {details.mediaDescription &&
                                                                                        details.mediaDescription.length >=
                                                                                        15 ? (
                                                                                        <div className="flex-box">
                                                                                            <p>
                                                                                                {details.mediaDescription.substr(
                                                                                                    0,
                                                                                                    50
                                                                                                )}
                                                                                                {"... "}
                                                                                            </p>
                                                                                            {/*<div className="bottom-links">*/}
                                                                                            {/*            <a*/}
                                                                                            {/*                href="#"*/}
                                                                                            {/*                onClick={(e) => {*/}
                                                                                            {/*                    e.preventDefault();*/}
                                                                                            {/*            handleQRModalShow(*/}
                                                                                            {/*                details.mediaDescription*/}
                                                                                            {/*            );*/}
                                                                                            {/*        }}*/}
                                                                                            {/*    >*/}
                                                                                            {/*        Show More*/}
                                                                                            {/*    </a>*/}
                                                                                            {/*</div>*/}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <p>{details.mediaDescription}</p>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <div className="thumb-list-text no-data">
                                                                                    <p>No Description Available</p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Link>
                                                                ) : details.mediaType == "pdf" ? (
                                                                    <Link className="h-100 d-inline-block w-100"

                                                                        to=
                                                                        {{
                                                                            pathname:
                                                                                "/QRDescription/" + details.mediaId,
                                                                            state: {
                                                                                parentQrCodeIdnew: parentQrCodeId
                                                                                    ? parentQrCodeId
                                                                                    : id,
                                                                                homeId: savedHomeId, 
                                                                            },
                                                                        }}
                                                                    >

                                                                        <div
                                                                            class="list-thumbnail"
                                                                        >
                                                                            <div className="control-section">
                                                                            </div>

                                                                            <div class="thumbnail-list-img">

                                                                                <img src={PDF}></img>
                                                                            </div>
                                                                            {details.mediaDescription ? (
                                                                                <div class="Qr-list-text">
                                                                                    <strong>QR - {qRCode}</strong>
                                                                                    <div className="flex-box">
                                                                                        <p>
                                                                                            {details.mediaDescription.substr(
                                                                                                0,
                                                                                                50
                                                                                            )}
                                                                                            {"... "}
                                                                                        </p>
                                                                                        {/*<div className="bottom-links">*/}
                                                                                        {/*            <a*/}
                                                                                        {/*                href="#"*/}
                                                                                        {/*                onClick={(e) => {*/}
                                                                                        {/*                    e.preventDefault();*/}
                                                                                        {/*            handleQRModalShow(*/}
                                                                                        {/*                details.mediaDescription*/}
                                                                                        {/*            );*/}
                                                                                        {/*        }}*/}
                                                                                        {/*    >*/}
                                                                                        {/*        Show More*/}
                                                                                        {/*    </a>*/}
                                                                                           
                                                                                        {/*</div>*/}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="thumb-list-text no-data">
                                                                                    <p>No Description Available</p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Link>
                                                                ) :
                                                                    details.mediaType == "Text (HTML)" ? (
                                                                        <Link className="h-100 d-inline-block w-100"
                                                                            to={{
                                                                                pathname:
                                                                                    "/QRDescription/" + details.mediaId,
                                                                                state: {
                                                                                    parentQrCodeIdnew: parentQrCodeId
                                                                                        ? parentQrCodeId
                                                                                        : id,
                                                                                    homeId: savedHomeId, 
                                                                                },
                                                                            }}
                                                                        >
                                                                            <div
                                                                                class="list-thumbnail"
                                                                            >
                                                                                <div>
                                                                                    <div dangerouslySetInnerHTML={{
                                                                                        __html: details.mediaDescription.substr(
                                                                                            0,
                                                                                            1700
                                                                                        ),
                                                                                    }}>

                                                                                    </div>

                                                                                    {details.mediaDescription.length > 1700 ? (
                                                                                                <a
                                                                                                    href="#"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                handleQRModalShow(
                                                                                                    <div dangerouslySetInnerHTML={{
                                                                                                        __html: details.mediaDescription
                                                                                                    }}>
                                                                                                    </div>

                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            Show More
                                                                                        </a>) : (
                                                                                        "")
                                                                                    }






                                                                                </div>

                                                                                {/* <p>{details.mediaDescription.replace(/(<([^>]+)>)/gi, "")}</p>*/}

                                                                                <div>


                                                                                </div>

                                                                            </div>
                                                                        </Link>
                                                                    ) :
                                                                        details.mediaType == "Video Url" ? (
                                                                            <Link className="h-100 d-inline-block w-100"
                                                                                to={{
                                                                                    pathname:
                                                                                        "/QRDescription/" + details.mediaId,
                                                                                    state: {
                                                                                        parentQrCodeIdnew: parentQrCodeId
                                                                                            ? parentQrCodeId
                                                                                            : id,
                                                                                        homeId: savedHomeId, 
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    class="list-thumbnail"
                                                                                >
                                                                                    <div class="thumbnail-list-img">
                                                                                        <ReactPlayer
                                                                                            // url="https://vimeo.com/291715535"
                                                                                            url={details.filename}
                                                                                            width="100%"
                                                                                            height="100%"
                                                                                            controls={true}
                                                                                            playing={false}
                                                                                        />
                                                                                        <div className="overlay-play-btn"><img src={play} alt="play" ></img></div>
                                                                                    </div>
                                                                                    {details.mediaDescription ? (
                                                                                        <div
                                                                                            class="Qr-list-text"
                                                                                        /* onClick={() => next(details.mediaId)}*/
                                                                                        >
                                                                                            <strong>QR - {qRCode}</strong>
                                                                                            <div className="flex-box">
                                                                                                <p>
                                                                                                    {details.mediaDescription.substr(
                                                                                                        0,
                                                                                                        60
                                                                                                    )}
                                                                                                    {"... "}
                                                                                                </p>
                                                                                                {/*<div className="bottom-links">*/}
                                                                                                {/*            <a*/}
                                                                                                {/*                href="#"*/}
                                                                                                {/*                onClick={(e) => {*/}
                                                                                                {/*                    e.preventDefault();*/}
                                                                                                {/*            handleQRModalShow(*/}
                                                                                                {/*                details.mediaDescription*/}
                                                                                                {/*            );*/}
                                                                                                {/*        }}*/}
                                                                                                {/*    >*/}
                                                                                                {/*        Show More*/}
                                                                                                {/*    </a>*/}
                                                                                                {/*</div>*/}
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div className="thumb-list-text no-data">
                                                                                            <p>No Description Available</p>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </Link>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ) : (
                            <p>No Data Found</p>
                        )}
                    </div>
                </div>
            </section>

            <Modal
                show={modal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-centered"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Media Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div class="list-text">
                            <p>{detailDesc}</p>
                        </div>
                    }
                </Modal.Body>
            </Modal>

            <Modal
                show={showmodal}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-dialog-zoom"
            >
                <Modal.Header className="pt-3 pb-2" closeButton>

                </Modal.Header>
                <Modal.Body>
                    {
                        <div className="img-section m-0 flex-wrap d-flex align-items-center">
                            <img className=" w-100" src={showZoomImage}></img>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            <Footer></Footer>
            <div
                class="modal fade"
                id="zoom-btn"
                tabindex="-1"
                aria-labelledby="zoom-btn"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-sm modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button
                                type="button"
                                class="btn-closee"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <img src={Close}></img>
                            </button>
                            <div class="QRImg">
                                <img src={qrGroupingDetails.thumbnailName} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default connect()(QRThumbnail);
